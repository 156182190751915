import clsx from "clsx";

import { useForm } from "react-hook-form";
import { useAuth } from "../hooks/useAuth";
import { useLocation } from "react-router-dom";

import { useAPI } from "../hooks/useAPI";

export default function Auth() {
  const authAPI = useAPI("users/auth");

  let location = useLocation();
  const { login } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    let { error, resp } = await authAPI.post(data);

    if (error) return;

    let from = location.state?.from?.pathname || "/";
    from = "/vacancies";
    if (resp.user?.roles && resp.user?.roles[0] === "ROLE_MANAGER_WORKER") from = "/workers";
    login(resp, from);
  };

  return (
    <div className="modal d-block bg-secondary">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content rounded-4 shadow">
          <div className="modal-header p-5 pb-4 border-bottom-0">
            <h2 className="fw-bold mb-0">Авторизация</h2>
          </div>

          <div className="modal-body p-5 pt-0">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className={clsx(
                    "form-control rounded-3",
                    errors.email && "is-invalid"
                  )}
                  placeholder="email@example.com"
                  {...register("email", { required: true })}
                  autoFocus
                />
                <label>Email</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className={clsx(
                    "form-control rounded-3",
                    errors.password && "is-invalid"
                  )}
                  placeholder="Пароль"
                  {...register("password", { required: true })}
                />
                <label>Пароль</label>
              </div>
              <button
                className="w-100 mb-2 btn btn-lg rounded-3 btn-primary"
                type="submit"
              >
                Войти
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
