import { useAPI } from "../hooks/useAPI";
import {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
  //  useContext,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate, useParams } from "react-router-dom";

import {
  TimestampCellRenderer,
  RoleCellRenderer,
  StatusCellRenderer,
  DefaultColDef,
  BULK_ACTION,
  onButtonBulkAction,
} from "../helpers/ag-grid";

// import { SettingsContext } from "../contexts/Settings";

export default function Users() {
  const navigate = useNavigate();
  // const {settings, setSettings} = useContext(SettingsContext);

  const gridRef = useRef();
  const [selectedIDs, setSelectedIDs] = useState([]);

  const usersAPI = useAPI("users");

  const fetchUsers = useCallback(async () => {
    const role = roleFromPath();

    const path = role === "" ? "/users" : "/users?roles=" + role;

    await usersAPI.get(path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const roleFromPath = () => {
    if (window.location.pathname.indexOf("/users") !== -1) return "";
    if (window.location.pathname.indexOf("/managers") !== -1)
      return "ROLE_MANAGER";
    if (window.location.pathname.indexOf("/clients") !== -1)
      return "ROLE_CLIENT";
    if (window.location.pathname.indexOf("/responsibles") !== -1)
      return "ROLE_RESPONSIBLE";


    return "";
  };

  const urlFromPath = () => {
    if (window.location.pathname.indexOf("/users") !== -1) return "users";
    if (window.location.pathname.indexOf("/managers") !== -1) return "managers";
    if (window.location.pathname.indexOf("/clients") !== -1) return "clients";
    if (window.location.pathname.indexOf("/responsibles") !== -1) return "responsibles";

    return "users";
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, window.location.pathname]);

  const columnDefs = roleFromPath() === "ROLE_RESPONSIBLE" ? [
      {
        field: "id",
        headerName: "ID",
        maxWidth: 90,
        checkboxSelection: false,
        headerCheckboxSelection: false,
      },
      {
        field: "createdAt",
        headerName: "Создан",
        maxWidth: 120,
        cellRenderer: TimestampCellRenderer,
      },
      {
        field: "updatedAt",
        headerName: "Обновлен",
        maxWidth: 120,
        cellRenderer: TimestampCellRenderer,
      },
      { field: "fullName", headerName: "ФИО" },
    ] : [
      {
        field: "id",
        headerName: "ID",
        maxWidth: 90,
        checkboxSelection: false,
        headerCheckboxSelection: false,
      },
      {
        field: "createdAt",
        headerName: "Создан",
        maxWidth: 120,
        cellRenderer: TimestampCellRenderer,
      },
      {
        field: "updatedAt",
        headerName: "Обновлен",
        maxWidth: 120,
        cellRenderer: TimestampCellRenderer,
      },
      {
        field: "roles",
        headerName: "Роли",
        maxWidth: 170,
        cellRenderer: RoleCellRenderer,
      },
      { field: "email", headerName: "Email" },
      { field: "fullName", headerName: "ФИО" },
    ];

  const cellClickedListener = useCallback(
    (event) => {
      let id = event?.data?.id;

      if (id === 0) {
        alert("Invalid id");
        return;
      }

      navigate("/" + urlFromPath() + "/" + id);
    },
    [navigate]
  );

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    setSelectedIDs(selectedRows.map((v) => v.id));
  }, []);

  const onButtonCreate = () => {
    navigate("/" + urlFromPath() + "/0");
  };

  const onBulkActionComplete = async () => {
    await fetchUsers();
    gridRef.current.api.deselectAll();
  };

  return (
    <main style={{ padding: "10px 0" }}>
      <div className="itemsViewHeader">
        <div className="buttons">
          <button
            type="button"
            className="btn btn-success btn-sm"
            onClick={onButtonCreate}
          >
            Создать
          </button>
        </div>
        {false && (
          <div className="buttons">
            <button
              type="button"
              className="btn btn-danger btn-sm"
              onClick={() =>
                onButtonBulkAction(
                  usersAPI,
                  "users",
                  BULK_ACTION.DELETE,
                  1,
                  selectedIDs,
                  onBulkActionComplete
                )
              }
              disabled={selectedIDs.length === 0}
            >
              Delete
            </button>
          </div>
        )}
      </div>

      <div
        className="ag-theme-alpine"
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={usersAPI.items}
          columnDefs={columnDefs}
          defaultColDef={DefaultColDef}
          rowSelection="multiple"
          onCellClicked={cellClickedListener}
          onSelectionChanged={onSelectionChanged}
        ></AgGridReact>
      </div>
    </main>
  );
}
