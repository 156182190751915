import { useAPI } from "../hooks/useAPI";
import { useCallback, useEffect, useRef, useMemo, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate } from "react-router-dom";
import SMSModal from "../components/SMSModal";
import { PositionCellRenderer, DefaultColDef } from "../helpers/ag-grid";

export default function Workers() {
    const OBJECT_PREFIX = "workers";
    const navigate = useNavigate();

    const gridRef = useRef();
    const [selectedIDs, setSelectedIDs] = useState([]);
    const [objects, setObjects] = useState([]);
    const [isAllSelected, setIsAllSelected] = useState(false);
    const [showSMSModal, setShowSMSModal] = useState(false);

    const usersAPI = useAPI(OBJECT_PREFIX);

    const fetchUsers = useCallback(async () => {
        const p = urlFromPath() === "worker_applications" ? "" : "?isCRM=true";
        let { resp } = await usersAPI.get(OBJECT_PREFIX + p);

        for (let i = 0; i < resp.length; i++) {
            if (resp[i].status === 0) resp[i].status = "Не активен";
            if (resp[i].status === 1) resp[i].status = "Готов к работе";
            if (resp[i].status === 2) resp[i].status = "Требует уточнений";
            if (resp[i].status === 3) resp[i].status = "Временно не готов к работе";

            if (resp[i].sex === 0) resp[i].sex = "Женский";
            if (resp[i].sex === 1) resp[i].sex = "Мужской";

            resp[i].age =
                resp[i].dob > 0
                    ? new Date().getFullYear() -
                    new Date(resp[i].dob * 1000).getFullYear()
                    : "";

            let work_region_title = "";
            resp[i].workRegion.map((v) => (work_region_title += v.title + " "));
            resp[i].work_region_title = work_region_title;
        }

        setObjects(resp);
    }, []);

    const urlFromPath = () => {
        if (window.location.pathname.indexOf("/workers") !== -1) return "workers";
        if (window.location.pathname.indexOf("/worker_applications") !== -1)
            return "worker_applications";

        return "workers";
    };

    useEffect(() => {
        fetchUsers();
    }, [fetchUsers, window.location.pathname]);

    const columnDefs = useMemo(
        () => [
            {
                field: "id",
                headerName: "ID",
                maxWidth: 90,
                checkboxSelection: true,
                headerCheckboxSelection: true,
            },
            {
                field: "status",
                headerName: "Статус",
                maxWidth: 120,
            },
            { field: "fullName", headerName: "ФИО" },
            { field: "email", headerName: "Email" },
            {
                field: "position",
                headerName: "Должность",
                maxWidth: 140,
                cellRenderer: PositionCellRenderer,
                filterValueGetter: (p) => (p.data ? p.data?.position?.title : ""),
            },
            {
                field: "city",
                headerName: "Город",
                maxWidth: 180,
                cellRenderer: PositionCellRenderer,
                filterValueGetter: (p) => (p.data ? p.data?.city?.title : ""),
            },
            {
                field: "sex",
                headerName: "Пол",
                maxWidth: 100,
            },
            {
                field: "age",
                headerName: "Возраст",
                maxWidth: 100,
            },
            {
                field: "work_region_title",
                headerName: "Регион",
                maxWidth: 150,
            },
        ],
        []
    );

    const columnDefsApplications = useMemo(
        () => [
            {
                field: "id",
                headerName: "ID",
                maxWidth: 90,
                checkboxSelection: true,
                headerCheckboxSelection: true,
            },
            { field: "fullName", headerName: "ФИО" },
            {
                field: "phone",
                headerName: "Телефон",
            },
        ],
        []
    );

    const cellClickedListener = useCallback(
        (event) => {
            let id = event?.data?.id;

            if (id === 0) {
                alert("Invalid id");
                return;
            }

            const prefix =
                urlFromPath() === "worker_applications"
                    ? "worker_applications"
                    : "workers";
            navigate("/" + prefix + "/" + id);
        },
        [navigate]
    );

    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelectedIDs(selectedRows.map((v) => v.id));
    }, []);

    const handleSelectAll = () => {
        if (isAllSelected) {
            gridRef.current.api.deselectAll();
            setSelectedIDs([]);
        } else {
            gridRef.current.api.selectAll();
            const allIDs = objects.map((worker) => worker.id);
            setSelectedIDs(allIDs);
        }
        setIsAllSelected(!isAllSelected);
    };

    const handleSendSMS = () => {
        setShowSMSModal(true);
    };

    const onButtonCreate = () => {
        navigate("/" + OBJECT_PREFIX + "/0");
    };

    const onButtonAdd = () => {
        navigate("/" + OBJECT_PREFIX + "/0");
    };

    const onBulkActionComplete = async () => {
        await fetchUsers();
        gridRef.current.api.deselectAll();
    };

    const handleDownloadCSV = async () => {
        if (selectedIDs.length === 0) {
            alert("Пожалуйста, выберите работников для скачивания");
            return;
        }

        try {
            const response = await usersAPI.post(
                { ids: selectedIDs },
                "/download-workers-csv",
                { responseType: 'blob' }
            );

            const blob = new Blob([response.resp], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'workers.csv');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error("Ошибка при скачивании CSV:", error);
        }
    };

    return (
        <main style={{ padding: "10px 0" }}>
            <div className="itemsViewHeader">
                <div className="buttons">
                    {urlFromPath() === "worker_applications" && (
                        <button
                            type="button"
                            className="btn btn-success btn-sm"
                            onClick={onButtonAdd}
                        >
                            Добавить работника
                        </button>
                    )}
                    {urlFromPath() !== "worker_applications" && (
                        <button
                            type="button"
                            className="btn btn-success btn-sm"
                            onClick={onButtonCreate}
                        >
                            Создать
                        </button>
                    )}
                </div>
                <button onClick={handleSelectAll}>
                    {isAllSelected ? "Отменить выбор всех" : "Выбрать всех"}
                </button>
                {selectedIDs.length > 0 && (
                    <>
                        <button onClick={handleSendSMS}>Отправить SMS</button>
                        <button onClick={handleDownloadCSV}>Скачать CSV</button>
                    </>
                )}
            </div>

            <div
                className="ag-theme-alpine"
                style={{ height: "100%", width: "100%" }}
            >
                <AgGridReact
                    ref={gridRef}
                    rowData={objects}
                    columnDefs={
                        urlFromPath() === "worker_applications"
                            ? columnDefsApplications
                            : columnDefs
                    }
                    defaultColDef={DefaultColDef}
                    rowSelection="multiple"
                    onCellClicked={cellClickedListener}
                    onSelectionChanged={onSelectionChanged}
                ></AgGridReact>
            </div>
            {showSMSModal && (
                <SMSModal
                    selectedWorkers={selectedIDs}
                    onClose={() => setShowSMSModal(false)}
                />
            )}
        </main>
    );
}