import clsx from "clsx";
import { FileUpload } from "../components/FileUpload";
import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);

export const ETYPE = {
  INPUT_TEXT: 0,
  INPUT_PASSWORD: 1,
  INPUT_NUMBER: 2,
  INPUT_EMAIL: 3,
  SELECT: 4,
  RADIO: 5,
  CHECKBOX: 6,
  FILE_UPLOAD: 7,
  REACT_SELECT_MULTIPLE: 8,
  INPUT_TEXTAREA: 9,
};

const getInputType = (type) => {
  if (type === ETYPE.INPUT_TEXT) return "text";
  if (type === ETYPE.INPUT_PASSWORD) return "password";
  if (type === ETYPE.INPUT_NUMBER) return "number";
  if (type === ETYPE.INPUT_EMAIL) return "email";

  return "text";
};

const getCellSize = (cells, ci) => {
  let size = 10;

  if (cells.length === 2) size = 5;

  if (cells.length === 3 && ci !== 2) size = 3;
  if (cells.length === 3 && ci === 2) size = 4;

  return "col-sm-" + size;
};

export const Rows = (props) => {
  const { activeTab, rows, form } = props;

  return rows[activeTab].map((row, ri) => (
    <div className="mb-3 row" key={"tab" + activeTab + "row" + ri}>
      <label className="col-sm-2 col-form-label col-form-label-sm">
        {row.label}
      </label>
      {row.cells.map((cell, ci) => (
        <div
          className={getCellSize(row.cells, ci)}
          key={"row" + ri + "cell" + ci}
        >
          {[
            ETYPE.INPUT_TEXT,
            ETYPE.INPUT_PASSWORD,
            ETYPE.INPUT_NUMBER,
            ETYPE.INPUT_EMAIL,
          ].indexOf(cell.type) !== -1 && (
            <input
              disabled={cell.is_disabled}
              type={getInputType(cell.type)}
              className={clsx(
                "form-control form-control-sm",
                form.errors[cell.field] && "is-invalid"
              )}
              placeholder={cell.placeholder}
              {...form.register(cell.field, cell.validation)}
            />
          )}

          {[ETYPE.INPUT_TEXTAREA].indexOf(cell.type) !== -1 && (
            <textarea
              disabled={cell.is_disabled}
              type={getInputType(cell.type)}
              className={clsx(
                "form-control form-control-sm",
                form.errors[cell.field] && "is-invalid"
              )}
              placeholder={cell.placeholder}
              rows={7}
              {...form.register(cell.field, cell.validation)}
            />
          )}

          {[ETYPE.FILE_UPLOAD].indexOf(cell.type) !== -1 && (
            <FileUpload
              value={cell.value}
              isMulti={cell.isMulti}
              onUpload={(file) => cell.onChange(cell.field, file)}
            />
          )}

          {[ETYPE.SELECT].indexOf(cell.type) !== -1 && (
            <select
              className={clsx(
                "form-select form-select-sm",
                form.errors.status && "is-invalid"
              )}
              {...form.register(cell.field, cell.validation)}
            >
              {cell.data.map((value, vi) => (
                <option
                  key={"row" + ri + "cell" + ci + "value" + vi}
                  value={value.value}
                >
                  {value.label}
                </option>
              ))}
            </select>
          )}

          {[ETYPE.REACT_SELECT_MULTIPLE].indexOf(cell.type) !== -1 && (
            <Select
              options={cell.data}
              isClearable={true}
              isMulti={cell.isMulti}
              isSearchable={true}
              noOptionsMessage={() => "Нет значений"}
              placeholder="Выберите значение"
              getOptionLabel={(option) =>
                cell.labelField ? option[cell.labelField] : option.title
              }
              getOptionValue={(option) => option}
              onChange={cell.onChange}
              value={cell.value}
            />
          )}

          {[ETYPE.CALENDAR].indexOf(cell.type) !== -1 && (
            <DatePicker
              selected={cell.value}
              onChange={cell.onChange}
              locale="ru"
              showTimeSelect={cell.isTime}
              showTimeSelectOnly={cell.isTime}
              timeIntervals={15}
              dateFormat={cell.isTime ? "HH:mm" : "dd/MM/yyyy"}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
          )}
        </div>
      ))}
    </div>
  ));
};
