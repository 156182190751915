import "./css/app.css";
import "./css/screen.css";
import "./css/modal.css";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./hooks/useAuth";

import { Sidebar } from "./components/Sidebar";
import { SettingsProvider } from "./contexts/Settings";

export default function App() {
  const location = useLocation();
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }

  return (
    <SettingsProvider>
      <main className="d-flex flex-nowrap">
        <Sidebar />
        <div className="b-example-divider b-example-vr"></div>
        <div className="container main-content">
          <Outlet />
        </div>
      </main>
    </SettingsProvider>
  );
}
