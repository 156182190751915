import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/", { replace: true });
  };

  return (
    <div className="pageNotFound">
      Page not found
      <button onClick={goHome}>Go Home</button>
    </div>
  );
}
