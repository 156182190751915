import { useAPI } from "../hooks/useAPI";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Rows, ETYPE } from "../components/Rows";

import { Tabs } from "../components/Tabs";
import { FormButtons } from "../components/FormButtons";

export default function User() {
  const roleFromPath = () => {
    if (window.location.pathname.indexOf("/users") !== -1) return "ROLE_ADMIN";
    if (window.location.pathname.indexOf("/managers") !== -1)
      return "ROLE_MANAGER";
    if (window.location.pathname.indexOf("/clients") !== -1)
      return "ROLE_CLIENT";
    if (window.location.pathname.indexOf("/responsibles") !== -1)
      return "ROLE_RESPONSIBLE";

    return "ROLE_ADMIN";
  };

  const OBJECT_PREFIX = "/users";
  const DEFAULT_OBJECT = {
    status: 1,
    role_title: roleFromPath(),
    email: roleFromPath() === "ROLE_RESPONSIBLE" ? +new Date() + "@example.com" : "",
    password_new: roleFromPath() === "ROLE_RESPONSIBLE" ? +new Date() + "@example.com" : "",
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({ defaultValues: DEFAULT_OBJECT });

  const avatar = watch("avatar");

  const [activeTab, setActiveTab] = useState(0);
  const params = useParams();

  const usersAPI = useAPI(
    params.id > 0 ? OBJECT_PREFIX + "/" + params.id : OBJECT_PREFIX
  );

  const urlFromPath = () => {
    if (window.location.pathname.indexOf("/users") !== -1) return "users";
    if (window.location.pathname.indexOf("/managers") !== -1) return "managers";
    if (window.location.pathname.indexOf("/clients") !== -1) return "clients";
    if (window.location.pathname.indexOf("/responsibles") !== -1) return "responsibles";

    return "users";
  };

  const fetchUser = useCallback(async () => {
    const { resp } = await usersAPI.get("", true);

    if (resp["roles"].length > 0) resp["role_title"] = resp["roles"][0];

    reset(resp);
  }, []);

  useEffect(() => {
    if (params.id > 0) fetchUser();
  }, [fetchUser, params]);

  const onSubmit = async (data) => {
    data["roles"] = [data["role_title"]];

    let { error } = await usersAPI.post(data);

    if (error) return;
    navigate("/" + urlFromPath());
  };

  const tabs = urlFromPath() === "responsibles" ? [{ label: "Данные" }] : [{ label: "Данные" }, { label: "Пароль" }];

  const rows =
    urlFromPath() === "responsibles" ? [
      [
        {
          label: "ФИО",
          cells: [
            {
              field: "fullName",
              type: ETYPE.INPUT_TEXT,
              validation: { required: false },
            },
          ],
        },
      ]
    ] :
      [
        [
          {
            label: "Статус",
            cells: [
              {
                field: "status",
                type: ETYPE.SELECT,
                validation: { required: false, valueAsNumber: true },
                data: [
                  { value: 0, label: "Не активен" },
                  { value: 1, label: "Активен" },
                  { value: 99, label: "Новая заявка" },
                ],
              },
            ],
          },
          {
            label: "Email",
            cells: [
              {
                field: "email",
                type: ETYPE.INPUT_EMAIL,
                validation: { required: true },
              },
            ],
          },
          {
            label: "ФИО",
            cells: [
              {
                field: "fullName",
                type: ETYPE.INPUT_TEXT,
                validation: { required: false },
              },
            ],
          },
          {
            label: "Роль",
            cells: [
              {
                field: "role_title",
                type: ETYPE.SELECT,
                validation: { required: true, valueAsNumber: false },
                data: [
                  { value: "ROLE_ADMIN", label: "ADMIN" },
                  { value: "ROLE_MANAGER", label: "MANAGER" },
                  { value: "ROLE_CLIENT", label: "CLIENT" },
                  { value: "ROLE_MANAGER_WORKER", label: "MANAGER_WORKER" },
                ],
              },
            ],
          },
        ],
        [
          {
            label: "Пароль",
            cells: [
              {
                field: "password_new",
                type: ETYPE.INPUT_PASSWORD,
                validation: { required: false },
              },
            ],
          },
          {
            label: "Повторите пароль",
            cells: [
              {
                field: "password_repeat",
                type: ETYPE.INPUT_PASSWORD,
                validation: { required: false },
              },
            ],
          },
        ],
      ];

  return (
    <main style={{ padding: "10px 0" }}>
      <Tabs {...{ tabs, activeTab, setActiveTab }} />

      <div className="form-container col-sm-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Rows {...{ activeTab, rows, form: { errors, register } }} />

          <FormButtons
            {...{
              isCreate: true,
              isCancel: true,
              isDelete: true,
              objectID: +params.id,
              uri: OBJECT_PREFIX,
              returnURI: "/" + urlFromPath(),
              objectAPI: usersAPI,
            }}
          />
        </form>
      </div>
    </main>
  );
}
