import { NavLink } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

import { useTranslation } from "react-i18next";

export const Sidebar = () => {
  const { t, i18n } = useTranslation();

  const { user, logout } = useAuth();

  const menuUsers = (
    <>
      <li className="nav-item">
        <NavLink className="nav-link link-dark" to="/users">
          Все пользователи
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink className="nav-link link-dark" to="/managers">
          Менеджеры
        </NavLink>
      </li>
    </>
  );

  return (
    <>
      <div
        className="d-flex flex-column flex-shrink-0 p-3 bg-light"
        style={{ width: "224px" }}
      >
        <a
          href=""
          className="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none menu-top"
          style={{ width: "100%", textAlign: "center" }}
        >
          <span className="fs-4" style={{ width: "100%", textAlign: "center" }}>
            Меню
          </span>
        </a>
        <hr className="menu-top" />
        <ul className="nav nav-pills flex-column mb-auto">
          {user?.user.roles &&
            user?.user.roles.length > 0 &&
            user?.user.roles[0] === "ROLE_ADMIN" &&
            menuUsers}

          {user?.user.roles &&
            user?.user.roles.length > 0 &&
            user?.user.roles[0] === "ROLE_MANAGER_WORKER" &&
            <>
              <li className="nav-item">
                <NavLink className="nav-link link-dark" to="/workers">
                  Работники
                </NavLink>
              </li>
            </>
          }

          {user?.user.roles &&
            user?.user.roles.length > 0 &&
            (user?.user.roles[0] !== "ROLE_CLIENT" && user?.user.roles[0] !== "ROLE_MANAGER_WORKER") && <>
              <li className="nav-item">
                <NavLink className="nav-link link-dark" to="/clients">
                  Заказчики
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link link-dark" to="/vacancies">
                  Заявки заказчиков
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link link-dark" to="/workers">
                  Работники
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link link-dark" to="/worker_applications">
                  Заявки работников
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link link-dark" to="/locations">
                  Объекты
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link link-dark" to="/responsibles">
                  Ответственные
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link link-dark" to="/pochta">
                  Почта России
                </NavLink>
              </li>
            </>
          }

          {user?.user.roles &&
            user?.user.roles.length > 0 &&
            user?.user.roles[0] === "ROLE_CLIENT" && <>
              <li className="nav-item">
                <NavLink className="nav-link link-dark" to="/vacancies">
                  Мои заявки
                </NavLink>
              </li></>
          }
        </ul>
        <hr />
        <div className="dropdown bottom_menu_links">
          <ul className="nav nav-pills flex-column mb-auto">
            <li className="nav-item">
              <a
                className="nav-link link-dark pointer signout"
                onClick={logout}
                href="#"
              >
                Выйти
              </a>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
