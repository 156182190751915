import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      users: "Users",
    },
  },
  ar: {
    translation: {
      users: "AR Users",
    },
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    supportedLngs: ["en", "ar"],
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });
export default i18next;
