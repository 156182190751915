import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.css";

import { AuthProvider } from "./hooks/useAuth";

import PageNotFound from "./components/PageNotFound";

import "./helpers/i18n";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

import App from "./App";
import Users from "./routes/Users";
import User from "./routes/User";
import Auth from "./routes/Auth";
import Vacancies from "./routes/Vacancies";
import Vacancy from "./routes/Vacancy";
import Workers from "./routes/Workers";
import Worker from "./routes/Worker";
import Locations from "./routes/Locations";
import Location from "./routes/Location";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <AuthProvider>
        <Routes>
          <Route path="auth" element={<Auth />} />

          <Route path="/" element={<App />}>
            <Route path="users" element={<Users />} />
            <Route path="users/:id" element={<User />} />

            <Route path="clients" element={<Users />} />
            <Route path="clients/:id" element={<User />} />

            <Route path="managers" element={<Users />} />
            <Route path="managers/:id" element={<User />} />

            <Route path="vacancies" element={<Vacancies />} />
            <Route path="vacancies/:id" element={<Vacancy />} />

            <Route path="workers" element={<Workers />} />
            <Route path="workers/:id" element={<Worker />} />

            <Route path="worker_applications" element={<Workers />} />
            <Route path="worker_applications/:id" element={<Worker />} />

            <Route path="locations" element={<Locations />} />
            <Route path="locations/:id" element={<Location />} />

            <Route path="responsibles" element={<Users />} />
            <Route path="responsibles/:id" element={<User />} />

            <Route path="pochta" element={<Vacancies />} />
            <Route path="pochta/:id" element={<Vacancy />} />

          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
