import { useAPI } from "../hooks/useAPI";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Rows, ETYPE } from "../components/Rows";

import { Tabs } from "../components/Tabs";
import { FormButtons } from "../components/FormButtons";

export default function Location() {
  const OBJECT_PREFIX = "/locations";
  const DEFAULT_OBJECT = {
    status: 1,
    title: "",
    description: "",
    address: "",
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({ defaultValues: DEFAULT_OBJECT });

  const [activeTab, setActiveTab] = useState(0);

  const params = useParams();

  const usersAPI = useAPI(
    params.id > 0 ? OBJECT_PREFIX + "/" + params.id : OBJECT_PREFIX
  );

  const fetchUser = useCallback(async () => {
    const { resp } = await usersAPI.get("", true);

    reset(resp);
  }, []);

  useEffect(() => {
    if (params.id > 0) fetchUser();
  }, [fetchUser, params]);

  const onSubmit = async (data) => {
    let { error } = await usersAPI.post(data);

    if (error) return;
    navigate(OBJECT_PREFIX);
  };

  const tabs =
      [{ label: "Данные" }];

  const rows =
       [
        [
          {
            label: "Название",
            cells: [
              {
                field: "title",
                type: ETYPE.INPUT_TEXT,
                validation: { required: true },
              },
            ],
          },
        ],
      ];

  return (
    <main style={{ padding: "10px 0" }}>
      <Tabs {...{ tabs, activeTab, setActiveTab }} />

      <div className="form-container col-sm-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Rows {...{ activeTab, rows, form: { errors, register } }} />

          <FormButtons
            {...{
              isCreate: true,
              isCancel: true,
              isDelete: true,
              objectID: +params.id,
              uri: OBJECT_PREFIX,
              returnURI: OBJECT_PREFIX,
              objectAPI: usersAPI,
            }}
          />
        </form>
      </div>
    </main>
  );
}
