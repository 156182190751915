import { useAPI } from "../hooks/useAPI";
import { useAuth } from "../hooks/useAuth";
import {
  useCallback,
  useEffect,
  useRef,
  useMemo,
  useState,
  //  useContext,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { useNavigate, useParams } from "react-router-dom";

import {
  TimestampCellRenderer,
  RoleCellRenderer,
  StatusCellRenderer,
  DefaultColDef,
  BULK_ACTION,
  onButtonBulkAction,
  CreatorCellRenderer,
  WorkersCellRenderer,
  PositionCellRenderer,
  TimeCellRenderer,
} from "../helpers/ag-grid";

import { saveAs } from 'file-saver';
import * as XLSX from "xlsx";

// import { SettingsContext } from "../contexts/Settings";

export default function Vacancies() {
  const OBJECT_PREFIX = "vacancies";
  const navigate = useNavigate();
  // const {settings, setSettings} = useContext(SettingsContext);

  const gridRef = useRef();
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [objects, setObjects] = useState([]);

  const usersAPI = useAPI(OBJECT_PREFIX);

  const { user } = useAuth();

  const fetchUsers = useCallback(async () => {
    const type = typeFromPath();

    let { resp } = await usersAPI.get(OBJECT_PREFIX + "?type=" + type);
    for (let i = 0; i < resp.length; i++) {
      if (resp[i].status === 0) resp[i].status = "Не активная";
      if (resp[i].status === 1) resp[i].status = "Активная";
      if (resp[i].status === 2) resp[i].status = "В работе";
      if (resp[i].status === 3) resp[i].status = "Завершена";
      if (resp[i].status === 4) resp[i].status = "Отменена";
    }

    setObjects(resp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const typeFromPath = () => {
    if (window.location.pathname.indexOf("/vacancies") !== -1) return 0;
    if (window.location.pathname.indexOf("/pochta") !== -1)
      return 1;

    return 0;
  };

  const urlFromPath = () => {
    if (window.location.pathname.indexOf("/vacancies") !== -1) return "vacancies";
    if (window.location.pathname.indexOf("/pochta") !== -1) return "pochta";

    return "users";
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, window.location.pathname]);

  const exportExcel = () => {
    let data = [];
    let filtered = gridRef.current.api.getRenderedNodes();

    let header = ["ФИО", "Объект", "Дата", "Пришел", "Ушел", "Отработано по табелю", "Оплаченные часы", "Оплаченная сумма", "Ставка"];

    filtered.map((v) => {
      data.push([
        WorkersCellRenderer({value: v.data.workers}),
        PositionCellRenderer({value: v.data.location}),
        TimestampCellRenderer({value: v.data.workStart}),
        TimeCellRenderer({value: v.data.arrivedAt}),
        TimeCellRenderer({value: v.data.leftAt}),
        v.data.hoursWorked,
        v.data.hoursPaid,
        v.data.sumPaid,
        v.data.hourlyRate
      ]);
    });

    console.log(data);

    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const ws = XLSX.utils.json_to_sheet(data);
    XLSX.utils.sheet_add_aoa(ws, [header], { origin: "A1" });

    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data2 = new Blob([excelBuffer], { type: fileType });
    saveAs(data2, "export" + fileExtension);
  }

  const columnDefs = typeFromPath() === 1 ? [
    {
      field: "workers",
      headerName: "ФИО",
      cellRenderer: WorkersCellRenderer,
      filterValueGetter: (p) => (p.data ? p.data?.workers[0]?.fullName : ""),
    },
    {
      field: "location",
      headerName: "Объект",
      cellRenderer: PositionCellRenderer,
      filterValueGetter: (p) => (p.data ? p.data?.location?.title : ""),
    },
    {
      field: "workStart",
      headerName: "Дата",
      maxWidth: 120,
      cellRenderer: TimestampCellRenderer,
    },
    { field: "arrivedAt", cellRenderer: TimeCellRenderer, headerName: "Пришел", maxwidth: 120 },
    { field: "leftAt", cellRenderer: TimeCellRenderer, headerName: "Ушел", maxwidth: 120 },
    { field: "hoursWorked", headerName: "Отработано по табелю", maxwidth: 120 },
    { field: "hoursPaid", headerName: "Оплаченные часы", maxwidth: 120 },
    { field: "sumPaid", headerName: "Оплаченная сумма", maxwidth: 120 },
    { field: "hourlyRate", headerName: "Ставка", maxwidth: 120 },

  ] : [
    {
      field: "id",
      headerName: "ID",
      maxWidth: 90,
      checkboxSelection: false,
      headerCheckboxSelection: false,
    },
    {
      field: "createdAt",
      headerName: "Создан",
      maxWidth: 120,
      cellRenderer: TimestampCellRenderer,
    },
    { field: "status", headerName: "Статус", maxwidth: 120 },
    {
      field: "creator",
      headerName: "Заказчик",
      cellRenderer: CreatorCellRenderer,
      filterValueGetter: (p) => (p.data ? p.data?.creator?.fullName : ""),
    },
  ];

  const cellClickedListener = useCallback(
    (event) => {
      let id = event?.data?.id;

      if (id === 0) {
        alert("Invalid id");
        return;
      }

      navigate("/" + urlFromPath() + "/" + id);
    },
    [navigate]
  );

  const onSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current.api.getSelectedRows();

    setSelectedIDs(selectedRows.map((v) => v.id));
  }, []);

  const onButtonCreate = () => {
    navigate("/" + urlFromPath() + "/0");
  };

  const onBulkActionComplete = async () => {
    await fetchUsers();
    gridRef.current.api.deselectAll();
  };

  return (
    <main style={{ padding: "10px 0" }}>
      <div className="itemsViewHeader">
        <div className="buttons">
          <button
            type="button"
            className="btn btn-success btn-sm"
            onClick={onButtonCreate}
          >
            {user.user.roles[0] !== "ROLE_CLIENT" ? "Создать" : "Подать заявку"}
          </button>
        </div>
        {typeFromPath() === 1 &&
        <div className="buttons">
          <button
              type="button"
              className="btn btn-warning btn-sm"
              onClick={exportExcel}
            >
              Экспорт в Excel
            </button>
        </div>
        }
        {false && (
          <div className="buttons">
            <button
              type="button"
              className="btn btn-danger btn-sm"
              onClick={() =>
                onButtonBulkAction(
                  usersAPI,
                  OBJECT_PREFIX,
                  BULK_ACTION.DELETE,
                  1,
                  selectedIDs,
                  onBulkActionComplete
                )
              }
              disabled={selectedIDs.length === 0}
            >
              Delete
            </button>
          </div>
        )}
      </div>

      <div
        className="ag-theme-alpine"
        style={{ height: "100%", width: "100%" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={objects}
          columnDefs={columnDefs}
          defaultColDef={DefaultColDef}
          rowSelection="multiple"
          onCellClicked={cellClickedListener}
          onSelectionChanged={onSelectionChanged}
        ></AgGridReact>
      </div>
    </main>
  );
}
