import React, { useState } from "react";
import { useAPI } from "../hooks/useAPI";

const SMSModal = ({ selectedWorkers, onClose }) => {
    const [smsText, setSmsText] = useState("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [response, setResponse] = useState(null);
    const [sendResponse, setSendResponse] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const checkSMSAPI = useAPI("/check-sms-send");
    const sendSMSAPI = useAPI("/sms-send");

    const handleCheckCost = async () => {
        setLoading(true);
        setError("");
        setResponse(null);

        try {
            const { error: apiError, resp } = await checkSMSAPI.post({
                ids: selectedWorkers,
                text: smsText,
            });

            if (apiError) {
                setError(apiError);
            } else {
                setResponse(resp);
                if (!resp.success) {
                    setError(resp.message || "Ошибка при проверке стоимости SMS");
                }
            }
        } catch (err) {
            setError(err.message || "Ошибка при проверке стоимости SMS");
        } finally {
            setLoading(false);
        }
    };

    const handleSend = async () => {
        setShowConfirm(false);
        setLoading(true);
        setError("");

        try {
            const { error: apiError, resp } = await sendSMSAPI.post({
                ids: selectedWorkers,
                text: smsText,
            });

            if (apiError) {
                setError(apiError);
            } else {
                setSendResponse(resp);
            }
        } catch (err) {
            setError(err.message || "Ошибка при отправке SMS");
        } finally {
            setLoading(false);
        }
    };

    const handleConfirm = () => {
        setShowConfirm(true);
    };

    const handleClose = () => {
        setSendResponse(null); // Сбрасываем ответ перед закрытием
        onClose();
    };

    return (
        <>
            {!sendResponse && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>Отправить SMS</h3>
                        <textarea
                            value={smsText}
                            onChange={(e) => setSmsText(e.target.value)}
                            placeholder="Введите текст SMS"
                        />
                        <div>Количество символов: {smsText.length}</div>
                        <div>Выбрано работников: {selectedWorkers.length}</div>
                        {error && <div className="error">{error}</div>}
                        {response && !response.success && <div className="error">{response.message}</div>}
                        {response && response.success && (
                            <div>
                                <div>Стоимость SMS: {response.total_cost}</div>
                                <div>Баланс: {response.balance}</div>
                                <button onClick={handleConfirm} disabled={loading}>
                                    {loading ? "Отправка..." : "Хочу сделать рассылку"}
                                </button>
                            </div>
                        )}
                        {!response && (
                            <button onClick={handleCheckCost} disabled={loading}>
                                {loading ? "Проверка..." : "Проверить стоимость"}
                            </button>
                        )}
                        <button onClick={onClose}>Закрыть</button>
                    </div>

                    {showConfirm && (
                        <div className="confirm-modal">
                            <div className="confirm-content">
                                <p>Вы уверены, что хотите отправить SMS?</p>
                                <button onClick={handleSend} disabled={loading}>
                                    Да
                                </button>
                                <button onClick={() => setShowConfirm(false)}>Нет</button>
                            </div>
                        </div>
                    )}
                </div>
            )}

            {sendResponse && (
                <div className="response-modal">
                    <div className="response-content">
                        <h3>Ответ сервера</h3>
                        <div>Статус: {sendResponse.success ? "Успешно" : "Ошибка"}</div>
                        <div>Сообщение: {sendResponse.message}</div>
                        <div>
                            <h4>Детали:</h4>
                            <ul>
                                <li>Статус: {sendResponse.details.status}</li>
                                <li>Код статуса: {sendResponse.details.status_code}</li>
                                <li>Баланс: {sendResponse.details.balance}</li>
                                <li>SMS:</li>
                                <ul>
                                    {Object.keys(sendResponse.details.sms).map((phone) => (
                                        <li key={phone}>
                                            Телефон: {phone} - Статус: {sendResponse.details.sms[phone].status}, Код статуса: {sendResponse.details.sms[phone].status_code}, Стоимость: {sendResponse.details.sms[phone].cost}, ID SMS: {sendResponse.details.sms[phone].sms_id}
                                        </li>
                                    ))}
                                </ul>
                            </ul>
                        </div>
                        <button onClick={handleClose}>Закрыть</button>
                    </div>
                </div>
            )}
        </>
    );
};

export default SMSModal;