import clsx from "clsx";

export const Tabs = (props) => {
  const { tabs, activeTab, setActiveTab } = props;

  const onTabClick = (e, index) => {
    e.preventDefault();
    setActiveTab(index);
  };

  return (
    <ul className="nav nav-tabs col-sm-7">
      {tabs.map((v, i) => (
        <li key={i} className="nav-item" onClick={(e) => onTabClick(e, i)}>
          <a className={clsx("nav-link", activeTab === i && "active")} href="/">
            {v.label}
          </a>
        </li>
      ))}
    </ul>
  );
};
