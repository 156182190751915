import React, { useEffect, useState } from "react";

import { useAPI } from "../hooks/useAPI";
import cloneDeep from "lodash/cloneDeep";

export const FileUpload = (props) => {
  const FILE_BASE_URL = process.env.REACT_APP_FILE_BASE_URL;
  const TMP_FILE_BASE_URL = FILE_BASE_URL;

  const objectAPI = useAPI();

  const [fileUploadProgress, setFileUploadProgress] = useState(0);

  const uploadFile = async (e) => {
    if (e.target.files.length === 0) return;

    let formData = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      formData.append("files[]", e.target.files[i]);
    }

    const { error, resp } = await objectAPI.upload(
      "/tmp_files",
      formData,
      (e) => {
        setFileUploadProgress(Math.round((100 * e.loaded) / e.total));
      }
    );

    setFileUploadProgress(0);
    e.target.value = null;

    if (error || resp.length === 0) return;

    console.log(props);
    if (!props.isMulti) {
      props.onUpload(resp);

      return;
    }

    let v = cloneDeep(props.value);
    v.push(resp);
    props.onUpload(v);
  };

  const deleteItem = (i) => {
    if (!props.isMulti) return props.onUpload();

    let v = cloneDeep(props.value);
    v.splice(i, 1);

    return props.onUpload(v);
  };
  return (
    <>
      <div className="input-group">
        <input
          type="file"
          className="form-control form-control-sm"
          aria-label="Upload"
          onChange={(e) => uploadFile(e)}
        />
        {fileUploadProgress > 0 && (
          <button
            disabled
            className="btn btn-outline-secondary btn-sm file-upload-progress"
            type="button"
          >
            {fileUploadProgress}%
          </button>
        )}
      </div>

      {props.value &&
        props.value.map((v, i) => (
          <div className="file-item" key={v.id}>
            <span className="delete-item" onClick={() => deleteItem(i)}>
              &#10005;
            </span>
            <a
              href={FILE_BASE_URL + v.filename}
              target="_blank"
              rel="noreferrer"
            >
              Файл
            </a>
          </div>
        ))}
    </>
  );
};
