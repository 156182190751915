import { useNavigate } from "react-router-dom";
import { BULK_ACTION, onButtonBulkAction } from "../helpers/ag-grid";

export const FormButtons = (props) => {
  const {
    isCreate,
    isAddToWorkers,
    isCancel,
    isDelete,
    objectID,
    uri,
    returnURI,
    objectAPI,
  } = props;

  const navigate = useNavigate();

  const onButtonCancel = () => {
    navigate(returnURI);
  };

  return (
    <div className="itemsViewHeader">
      <div className="buttons">
        {isAddToWorkers && (
          <button type="submit" className="btn btn-success btn-sm">
            {objectID === 0 ? "Добавить в работники" : "Добавить в работники"}
          </button>
        )}
        {isCreate && (
          <button type="submit" className="btn btn-success btn-sm">
            {objectID === 0 ? "Создать" : "Сохранить"}
          </button>
        )}
      </div>

      <div className="buttons">
        {isDelete && objectID > 0 && (
          <button
            type="button"
            className="btn btn-danger btn-sm"
            onClick={() =>
              onButtonBulkAction(
                objectAPI,
                uri,
                BULK_ACTION.DELETE,
                1,
                [objectID],
                onButtonCancel
              )
            }
          >
            Удалить
          </button>
        )}

        {isCancel && (
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            onClick={onButtonCancel}
          >
            Отменить
          </button>
        )}
      </div>
    </div>
  );
};
