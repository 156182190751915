import { useAPI } from "../hooks/useAPI";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { Rows, ETYPE } from "../components/Rows";

import { Tabs } from "../components/Tabs";
import { FormButtons } from "../components/FormButtons";

export default function Worker() {
  const OBJECT_PREFIX = "/workers";
  const DEFAULT_OBJECT = {
    status: 1,
    sex: 1,
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({ defaultValues: DEFAULT_OBJECT });

  const avatar = watch("avatar");

  const [activeTab, setActiveTab] = useState(0);
  const [positions, setPositions] = useState([]);
  const [citizenships, setCitizenships] = useState([]);
  const [cities, setCities] = useState([]);
  const [clients, setClients] = useState([]);
  const [workRegions, setWorkRegions] = useState([]);
  const [citizenshipValue, setCitizenshipValue] = useState();
  const [cityValue, setCityValue] = useState();
  const [positionValue, setPositionValue] = useState();
  const [dobValue, setDobValue] = useState();
  const [workRegionValue, setWorkRegionValue] = useState();
  const [passportPhoto, setPassportPhoto] = useState();
  const [employerValue, setEmployerValue] = useState();
  const [employmentFromValue, setEmploymentFromValue] = useState();
  const [employmentToValue, setEmploymentToValue] = useState();
  const [vacancies, setVacancies] = useState([]);
  const [vacancyValue, setVacancyValue] = useState([]);

  const params = useParams();

  const usersAPI = useAPI(
    params.id > 0 ? OBJECT_PREFIX + "/" + params.id : OBJECT_PREFIX
  );

  const fetchUser = useCallback(async () => {
    const { resp } = await usersAPI.get("", true);

    setCitizenshipValue(resp["citizenship"]);
    setCityValue(resp["city"]);
    setPositionValue(resp["position"]);
    setDobValue(resp["dob"] ? new Date(resp["dob"] * 1000) : null);
    setWorkRegionValue(resp["workRegion"]);
    if (resp["passportPhoto"] !== undefined && resp["passportPhoto"] !== null)
      setPassportPhoto(resp["passportPhoto"]);
    setEmployerValue(resp["employer"]);
    setEmploymentFromValue(
      resp["employmentFrom"] ? new Date(resp["employmentFrom"] * 1000) : null
    );
    setEmploymentToValue(
      resp["employmentTo"] ? new Date(resp["employmentTo"] * 1000) : null
    );
    setVacancyValue(resp["vacancy"]);

    reset(resp);
  }, []);

  const positionAPI = useAPI("/positions");

  const fetchPositions = useCallback(async () => {
    const { resp } = await positionAPI.get("", true);
    setPositions(resp);
  }, []);

  const citizenshipAPI = useAPI("/citizenships");

  const fetchCitizenships = useCallback(async () => {
    const { resp } = await citizenshipAPI.get("", true);
    setCitizenships(resp);
  }, []);

  const citiesAPI = useAPI("/cities");

  const fetchCities = useCallback(async () => {
    const { resp } = await citiesAPI.get("", true);
    setCities(resp);
  }, []);

  const vacanciesAPI = useAPI("/vacancies");

  const fetchVacancies = useCallback(async () => {
    const { resp } = await vacanciesAPI.get("", true);
    setVacancies(resp);
  }, []);

  const clientsAPI = useAPI("/users?roles=ROLE_CLIENT");

  const fetchClients = useCallback(async () => {
    const { resp } = await clientsAPI.get("", true);

    setClients(resp);
  }, []);

  const workRegionsAPI = useAPI("/work_regions");

  const fetchWorkRegions = useCallback(async () => {
    const { resp } = await workRegionsAPI.get("", true);
    setWorkRegions(resp);
  }, []);

  useEffect(() => {
    fetchPositions();
    fetchCitizenships();
    fetchCities();
    fetchVacancies();
    fetchClients();
    fetchWorkRegions();
    if (params.id > 0) fetchUser();
  }, [fetchUser, params]);

  const onSubmit = async (data) => {
    let { error } = await usersAPI.post(data);

    if (error) return;
    navigate(OBJECT_PREFIX);
  };

  const urlFromPath = () => {
    if (window.location.pathname.indexOf("/workers") !== -1) return "workers";
    if (window.location.pathname.indexOf("/worker_applications") !== -1)
      return "worker_applications";

    return "workers";
  };

  const tabs =
    urlFromPath() === "worker_applications"
      ? [{ label: "Данные" }]
      : [{ label: "Данные" }, { label: "Прикрепить к заказчику" }, { label: "Прикрепить к заявке" }];

  const rows =
    urlFromPath() === "worker_applications"
      ? [
        [
          {
            label: "ФИО",
            cells: [
              {
                field: "fullName",
                type: ETYPE.INPUT_TEXT,
                validation: { required: true },
              },
            ],
          },
          {
            label: "Email",
            cells: [
              {
                field: "email",
                type: ETYPE.INPUT_EMAIL,
                validation: { required: false },
              },
            ],
          },
          {
            label: "Телефон",
            cells: [
              {
                field: "phone",
                type: ETYPE.INPUT_TEXT,
                validation: { required: true },
              },
            ],
          },
        ],
      ]
      : [
        [
          {
            label: "ФИО",
            cells: [
              {
                field: "fullName",
                type: ETYPE.INPUT_TEXT,
                validation: { required: true },
              },
            ],
          },
          {
            label: "Email",
            cells: [
              {
                field: "email",
                type: ETYPE.INPUT_EMAIL,
                validation: { required: false },
              },
            ],
          },
          {
            label: "Статус",
            cells: [
              {
                field: "status",
                type: ETYPE.SELECT,
                validation: { required: true, valueAsNumber: true },
                data: [
                  { value: 0, label: "Не активен" },
                  { value: 1, label: "Готов к работе" },
                  { value: 2, label: "Требует уточнений" },
                  { value: 3, label: "Временно не готов к работе" },
                ],
              },
            ],
          },
          {
            label: "Паспорт",
            cells: [
              {
                field: "passportSeries",
                type: ETYPE.INPUT_NUMBER,
                validation: { required: false },
                placeholder: "Серия",
              },
              {
                field: "passportNum",
                type: ETYPE.INPUT_NUMBER,
                validation: { required: false },
                placeholder: "Номер",
              },
            ],
          },
          {
            label: "Телефон",
            cells: [
              {
                field: "phone",
                type: ETYPE.INPUT_TEXT,
                validation: { required: true },
              },
            ],
          },
          {
            label: "Гражданство",
            cells: [
              {
                field: "citizenship",
                type: ETYPE.REACT_SELECT_MULTIPLE,
                validation: { required: false },
                data: citizenships,
                onChange: (values) => {
                  setValue("citizenship", values);
                  setCitizenshipValue(values);
                },
                value: citizenshipValue,
                isMulti: false,
              },
            ],
          },
          {
            label: "Город",
            cells: [
              {
                field: "city",
                type: ETYPE.REACT_SELECT_MULTIPLE,
                validation: { required: false },
                data: cities,
                onChange: (values) => {
                  setValue("city", values);
                  setCityValue(values);
                },
                value: cityValue,
                isMulti: false,
              },
            ],
          },
          {
            label: "Должность",
            cells: [
              {
                field: "position",
                type: ETYPE.REACT_SELECT_MULTIPLE,
                validation: { required: false },
                data: positions,
                onChange: (values) => {
                  setValue("position", values);
                  setPositionValue(values);
                },
                value: positionValue,
                isMulti: false,
              },
            ],
          },
          {
            label: "Регион работы",
            cells: [
              {
                field: "work_region",
                type: ETYPE.REACT_SELECT_MULTIPLE,
                validation: { required: false },
                data: workRegions,
                onChange: (values) => {
                  setValue("work_region", values);
                  setWorkRegionValue(values);
                },
                value: workRegionValue,
                isMulti: true,
              },
            ],
          },
          {
            label: "Пол",
            cells: [
              {
                field: "sex",
                type: ETYPE.SELECT,
                validation: { required: true, valueAsNumber: true },
                data: [
                  { value: 0, label: "Женский" },
                  { value: 1, label: "Мужской" },
                ],
              },
            ],
          },
          {
            label: "Telegram",
            cells: [
              {
                field: "telegram",
                type: ETYPE.INPUT_TEXT,
                validation: { required: false },
              },
            ],
          },
          {
            label: "Дата рождения",
            cells: [
              {
                field: "dob",
                type: ETYPE.CALENDAR,
                validation: { required: false },
                value: dobValue,
                onChange: (value) => {
                  setDobValue(value);
                  setValue("dob", value.getTime() / 1000);
                },
              },
            ],
          },
          {
            label: "Фото паспорта",
            cells: [
              {
                field: "passportPhoto",
                type: ETYPE.FILE_UPLOAD,
                validation: { required: false },
                value: passportPhoto !== undefined ? [passportPhoto] : [],
                onChange: (field, file) => {
                  setValue(field, file);
                  setPassportPhoto(file);
                },
              },
            ],
          },
          {
            label: "Комментарий",
            cells: [
              {
                field: "comment",
                type: ETYPE.INPUT_TEXTAREA,
                validation: { required: false },
              },
            ],
          },
        ],
        [
          {
            label: "Заказчик",
            cells: [
              {
                field: "employer",
                type: ETYPE.REACT_SELECT_MULTIPLE,
                validation: { required: false },
                data: clients,
                onChange: (values) => {
                  setValue("employer", values);
                  setEmployerValue(values);
                },
                value: employerValue,
                isMulti: false,
                labelField: "fullName",
              },
            ],
          },
          {
            label: "Дата начала",
            cells: [
              {
                field: "employmentFrom",
                type: ETYPE.CALENDAR,
                validation: { required: false },
                value: employmentFromValue,
                onChange: (value) => {
                  setEmploymentFromValue(value);
                  setValue("employmentFrom", value.getTime() / 1000);
                },
              },
            ],
          },
          {
            label: "Дата окончания",
            cells: [
              {
                field: "employmentTo",
                type: ETYPE.CALENDAR,
                validation: { required: false },
                value: employmentToValue,
                onChange: (value) => {
                  setEmploymentToValue(value);
                  setValue("employmentTo", value.getTime() / 1000);
                },
              },
            ],
          },
        ],
        [
          {
            label: "Заявка",
            cells: [
              {
                field: "vacancy",
                type: ETYPE.REACT_SELECT_MULTIPLE,
                validation: { required: false },
                data: vacancies,
                labelField: "address",
                onChange: (values) => {
                  setValue("vacancy", values);
                  setVacancyValue(values);
                },
                value: vacancyValue,
                isMulti: false,
              },
            ],
          },
        ]
      ];

  return (
    <main style={{ padding: "10px 0" }}>
      <Tabs {...{ tabs, activeTab, setActiveTab }} />

      <div className="form-container col-sm-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Rows {...{ activeTab, rows, form: { errors, register } }} />

          <FormButtons
            {...{
              isAddToWorkers: urlFromPath() === "worker_applications",
              isCreate: urlFromPath() !== "worker_applications",
              isCancel: true,
              isDelete: true,
              objectID: +params.id,
              uri: OBJECT_PREFIX,
              returnURI: OBJECT_PREFIX,
              objectAPI: usersAPI,
            }}
          />
        </form>
      </div>
    </main>
  );
}
