import { useAPI } from "../hooks/useAPI";
import React, { useCallback, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../hooks/useAuth";

import { Rows, ETYPE } from "../components/Rows";

import { Tabs } from "../components/Tabs";
import { FormButtons } from "../components/FormButtons";

export default function Vacancy() {
  const typeFromPath = () => {
    if (window.location.pathname.indexOf("/vacancies") !== -1) return 0;
    if (window.location.pathname.indexOf("/pochta") !== -1)
      return 1;

    return 0;
  };

  const urlFromPath = () => {
    if (window.location.pathname.indexOf("/vacancies") !== -1) return "vacancies";
    if (window.location.pathname.indexOf("/pochta") !== -1) return "pochta";

    return "vacancies";
  };

  const OBJECT_PREFIX = "/vacancies";
  const DEFAULT_OBJECT = {
    status: 1,
    address: "",
    amount: 1,
    deadline: 0,
    responsibilities: "",
    hourlyRate: 0,
    note: "",
    balance: 0,
    advance: 0,
    remaining: 0,
    totalHours: 0,
    hoursWorked: 0,
    arrivedAt: null,
    leftAt: null,
    hoursPaid: 0,
    hoursNote: "",
    hourlyRateNote: "",
    type: typeFromPath(),
    sumPaid: 0,
  };

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    watch,
  } = useForm({ defaultValues: DEFAULT_OBJECT });

  const { user } = useAuth();
  const role = user.user.roles[0];

  const [activeTab, setActiveTab] = useState(0);
  const [workStartValue, setWorkStartValue] = useState();
  const [workEndValue, setWorkEndValue] = useState();
  const [positionValue, setPositionValue] = useState();
  const [creatorValue, setCreatorValue] = useState();
  const [paymentTypeValue, setPaymentTypeValue] = useState();
  const [taxRegimeValue, setTaxRegimeValue] = useState();
  const [positions, setPositions] = useState();
  const [creators, setCreators] = useState();
  const [paymentTypes, setPaymentTypes] = useState();
  const [taxRegimes, setTaxRegimes] = useState();
  const [documentsValue, setDocumentsValue] = useState([]);
  const [workers, setWorkers] = useState();
  const [workersValue, setWorkersValue] = useState();
  const [locations, setLocations] = useState();
  const [locationsValue, setLocationsValue] = useState();
  const [responsibles, setResponsibles] = useState();
  const [responsiblesValue, setResponsiblesValue] = useState();
  const [arrivedAtValue, setArrivedAtValue] = useState();
  const [leftAtValue, setLeftAtValue] = useState();

  const params = useParams();

  const workersAPI = useAPI("/workers?isCRM=true");

  const fetchWorkers = useCallback(async () => {
    const { resp } = await workersAPI.get("", true);
    setWorkers(resp);
  }, []);

  const positionAPI = useAPI("/positions");

  const fetchPositions = useCallback(async () => {
    const { resp } = await positionAPI.get("", true);
    setPositions(resp);
  }, []);

  const creatorsAPI = useAPI("/users?roles=ROLE_CLIENT");

  const fetchCreators = useCallback(async () => {
    const { resp } = await creatorsAPI.get("", true);
    setCreators(resp);
  }, []);

  const paymentTypesAPI = useAPI("/payment_types");

  const fetchPaymentTypes = useCallback(async () => {
    const { resp } = await paymentTypesAPI.get("", true);
    setPaymentTypes(resp);
  }, []);

  const taxRegimesAPI = useAPI("/tax_regimes");

  const fetchTaxRegimes = useCallback(async () => {
    const { resp } = await taxRegimesAPI.get("", true);
    setTaxRegimes(resp);
  }, []);

  const usersAPI = useAPI(
    params.id > 0 ? OBJECT_PREFIX + "/" + params.id : OBJECT_PREFIX
  );

  const locationsAPI = useAPI("/locations");

  const fetchLocations = useCallback(async () => {
    const { resp } = await locationsAPI.get("", true);
    setLocations(resp);
  }, []);

  const responsiblesAPI = useAPI("/users?roles=ROLE_RESPONSIBLE");

  const fetchResponsibles = useCallback(async () => {
    const { resp } = await responsiblesAPI.get("", true);
    setResponsibles(resp);
  }, []);

  const fetchUser = useCallback(async () => {
    const { resp } = await usersAPI.get("", true);

    setWorkStartValue(
      resp["workStart"] ? new Date(resp["workStart"] * 1000) : null
    );
    setWorkEndValue(resp["workEnd"] ? new Date(resp["workEnd"] * 1000) : null);
    setPositionValue(resp["position"]);

    setCreatorValue(resp["creator"]);
    setPaymentTypeValue(resp["paymentType"]);
    setTaxRegimeValue(resp["taxRegime"]);
    setWorkersValue(resp["workers"]);
    setDocumentsValue(resp["documents"]);

    setLocationsValue(resp["location"]);
    setResponsiblesValue(resp["responsible"]);
    setWorkersValue(resp["workers"]);

    setArrivedAtValue(
      resp["arrivedAt"] ? new Date(resp["arrivedAt"] * 1000) : null
    );

    setLeftAtValue(
      resp["leftAt"] ? new Date(resp["leftAt"] * 1000) : null
    );

    reset(resp);
  }, []);

  useEffect(() => {
    fetchPositions();

    if (role !== "ROLE_CLIENT") {
      fetchCreators();
      fetchPaymentTypes();
      fetchTaxRegimes();
      fetchWorkers();
      fetchLocations();
      fetchResponsibles();
    }

    if (params.id > 0) fetchUser();
  }, [fetchUser, params]);

  const onSubmit = async (data) => {
    let { error } = await usersAPI.post(data);

    if (error) return;
    navigate("/" + urlFromPath());
  };

  const tabs = (role === "ROLE_CLIENT" || urlFromPath() === "pochta") ? [{ label: "Данные" }] : [
    { label: "Данные" },
    { label: "Расчеты" },
    { label: "Документы" },
  ];

  const clientRows = [
    [
      {
        label: "Адрес",
        cells: [
          {
            field: "address",
            type: ETYPE.INPUT_TEXT,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Количество",
        cells: [
          {
            field: "amount",
            type: ETYPE.INPUT_NUMBER,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Должность",
        cells: [
          {
            field: "position",
            type: ETYPE.REACT_SELECT_MULTIPLE,
            validation: { required: false },
            data: positions,
            onChange: (values) => {
              setValue("position", values);
              setPositionValue(values);
            },
            value: positionValue,
            isMulti: false,
          },
        ],
      },
      {
        label: "Дата начала",
        cells: [
          {
            field: "workStart",
            type: ETYPE.CALENDAR,
            validation: { required: false },
            value: workStartValue,
            onChange: (value) => {
              setWorkStartValue(value);
              setValue("workStart", value.getTime() / 1000);
            },
          },
        ],
      },
      {
        label: "Дата окончания",
        cells: [
          {
            field: "workEnd",
            type: ETYPE.CALENDAR,
            validation: { required: false },
            value: workEndValue,
            onChange: (value) => {
              setWorkEndValue(value);
              setValue("workEnd", value.getTime() / 1000);
            },
          },
        ],
      },
    ]
  ];

  const pochtaRows = [
    [
      {
        label: "ФИО",
        cells: [
          {
            field: "workers",
            type: ETYPE.REACT_SELECT_MULTIPLE,
            validation: { required: false },
            data: workers,
            onChange: (values) => {
              setValue("workers", values);
              setWorkersValue(values);
            },
            value: workersValue,
            isMulti: true,
            labelField: "fullName",
          },
        ],
      },
      {
        label: "Объект",
        cells: [
          {
            field: "location",
            type: ETYPE.REACT_SELECT_MULTIPLE,
            validation: { required: false },
            data: locations,
            onChange: (values) => {
              setValue("location", values);
              setLocationsValue(values);
            },
            value: locationsValue,
            isMulti: false,
          },
        ],
      },
      {
        label: "Дата",
        cells: [
          {
            field: "workStart",
            type: ETYPE.CALENDAR,
            validation: { required: false },
            value: workStartValue,
            onChange: (value) => {
              setWorkStartValue(value);
              setValue("workStart", value.getTime() / 1000);
            },
          },
        ],
      },
      {
        label: "Пришел",
        cells: [
          {
            field: "arrivedAt",
            type: ETYPE.CALENDAR,
            validation: { required: false },
            value: arrivedAtValue,
            isTime: true,
            onChange: (value) => {
              setArrivedAtValue(value);
              setValue("arrivedAt", value.getTime() / 1000);
            },
          },
        ],
      },
      {
        label: "Ушел",
        cells: [
          {
            field: "leftAt",
            type: ETYPE.CALENDAR,
            validation: { required: false },
            value: leftAtValue,
            isTime: true,
            onChange: (value) => {
              setLeftAtValue(value);
              setValue("leftAt", value.getTime() / 1000);
            },
          },
        ],
      },
      {
        label: "Отработано по табелю",
        cells: [
          {
            field: "hoursWorked",
            type: ETYPE.INPUT_NUMBER,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Оплаченные часы",
        cells: [
          {
            field: "hoursPaid",
            type: ETYPE.INPUT_NUMBER,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Оплаченная сумма",
        cells: [
          {
            field: "sumPaid",
            type: ETYPE.INPUT_NUMBER,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Примечание к часам",
        cells: [
          {
            field: "hoursNote",
            type: ETYPE.INPUT_TEXTAREA,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Примечание к ставке",
        cells: [
          {
            field: "hourlyRateNote",
            type: ETYPE.INPUT_TEXTAREA,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Ставка",
        cells: [
          {
            field: "hourlyRate",
            type: ETYPE.INPUT_NUMBER,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Ответственный",
        cells: [
          {
            field: "responsible",
            type: ETYPE.REACT_SELECT_MULTIPLE,
            validation: { required: false },
            data: responsibles,
            onChange: (values) => {
              setValue("responsible", values);
              setResponsiblesValue(values);
            },
            value: responsiblesValue,
            isMulti: false,
            labelField: "fullName",
          },
        ],
      },
      {
        label: "Детали",
        cells: [
          {
            field: "note",
            type: ETYPE.INPUT_TEXTAREA,
            validation: { required: false },
          },
        ],
      },
    ]
  ];

  const vacancyRows = [
    [
      {
        label: "Статус",
        cells: [
          {
            field: "status",
            type: ETYPE.SELECT,
            validation: { required: false, valueAsNumber: true },
            data: [
              { value: 0, label: "Не активна" },
              { value: 1, label: "Активна" },
              { value: 2, label: "В работе" },
              { value: 3, label: "Завершена" },
              { value: 4, label: "Отменена" },
            ],
          },
        ],
      },
      /*
      {
        label: "Работники",
        cells: [
          {
            field: "workers",
            type: ETYPE.REACT_SELECT_MULTIPLE,
            validation: { required: false },
            data: workers,
            onChange: (values) => {
              setValue("workers", values);
              setWorkersValue(values);
            },
            value: workersValue,
            isMulti: true,
            isLabelFullName: true,
          },
        ],
      },
      */
      {
        label: "Адрес",
        cells: [
          {
            field: "address",
            type: ETYPE.INPUT_TEXT,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Количество",
        cells: [
          {
            field: "amount",
            type: ETYPE.INPUT_NUMBER,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Дата начала",
        cells: [
          {
            field: "workStart",
            type: ETYPE.CALENDAR,
            validation: { required: false },
            value: workStartValue,
            onChange: (value) => {
              setWorkStartValue(value);
              setValue("workStart", value.getTime() / 1000);
            },
          },
        ],
      },
      {
        label: "Дата окончания",
        cells: [
          {
            field: "workEnd",
            type: ETYPE.CALENDAR,
            validation: { required: false },
            value: workEndValue,
            onChange: (value) => {
              setWorkEndValue(value);
              setValue("workEnd", value.getTime() / 1000);
            },
          },
        ],
      },
      {
        label: "Обязанности",
        cells: [
          {
            field: "responsibilities",
            type: ETYPE.INPUT_TEXTAREA,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Общее количество часов",
        cells: [
          {
            field: "totalHours",
            type: ETYPE.INPUT_NUMBER,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Стоимость чел/ч",
        cells: [
          {
            field: "hourlyRate",
            type: ETYPE.INPUT_NUMBER,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Примечание",
        cells: [
          {
            field: "note",
            type: ETYPE.INPUT_TEXTAREA,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Должность",
        cells: [
          {
            field: "position",
            type: ETYPE.REACT_SELECT_MULTIPLE,
            validation: { required: false },
            data: positions,
            onChange: (values) => {
              setValue("position", values);
              setPositionValue(values);
            },
            value: positionValue,
            isMulti: false,
          },
        ],
      },
      {
        label: "Заказчик",
        cells: [
          {
            field: "creator",
            type: ETYPE.REACT_SELECT_MULTIPLE,
            validation: { required: false },
            data: creators,
            onChange: (values) => {
              setValue("creator", values);
              setCreatorValue(values);
            },
            value: creatorValue,
            labelField: "fullName",
            isMulti: false,
          },
        ],
      },
      {
        label: "Режим налогообложения",
        cells: [
          {
            field: "taxRegime",
            type: ETYPE.REACT_SELECT_MULTIPLE,
            validation: { required: false },
            data: taxRegimes,
            onChange: (values) => {
              setValue("taxRegime", values);
              setTaxRegimeValue(values);
            },
            value: taxRegimeValue,
            isMulti: false,
          },
        ],
      },
    ],
    [
      {
        label: "Тип оплаты",
        cells: [
          {
            field: "paymentType",
            type: ETYPE.REACT_SELECT_MULTIPLE,
            validation: { required: false },
            data: paymentTypes,
            onChange: (values) => {
              setValue("paymentType", values);
              setPaymentTypeValue(values);
            },
            value: paymentTypeValue,
            isMulti: false,
          },
        ],
      },
      {
        label: "Аванс",
        cells: [
          {
            field: "advance",
            type: ETYPE.INPUT_NUMBER,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Баланс",
        cells: [
          {
            field: "balance",
            type: ETYPE.INPUT_NUMBER,
            validation: { required: false },
          },
        ],
      },
      {
        label: "Остаток",
        cells: [
          {
            field: "remaining",
            type: ETYPE.INPUT_NUMBER,
            validation: { required: false },
          },
        ],
      },
    ],
    [
      {
        label: "Документы",
        cells: [
          {
            field: "documents",
            isMulti: true,
            type: ETYPE.FILE_UPLOAD,
            validation: { required: false },
            value: documentsValue,
            onChange: (field, file) => {
              setValue(field, file);
              setDocumentsValue(file);
            },
          },
        ],
      },
    ],
  ];

  const rows = typeFromPath() === 1 ? pochtaRows : (role === "ROLE_CLIENT" ? clientRows : vacancyRows);

  return (
    <main style={{ padding: "10px 0" }}>
      <Tabs {...{ tabs, activeTab, setActiveTab }} />

      <div className="form-container col-sm-8">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Rows {...{ activeTab, rows, form: { errors, register } }} />

          <FormButtons
            {...{
              isCreate: true,
              isCancel: true,
              isDelete: true,
              objectID: +params.id,
              uri: OBJECT_PREFIX,
              returnURI: "/" + urlFromPath(),
              objectAPI: usersAPI,
            }}
          />
        </form>
      </div>
    </main>
  );
}
