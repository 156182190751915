import axios from "axios";
import { useState } from "react";
import { useAuth } from "./useAuth";
import { useTranslation } from "react-i18next";

export const useAPI = (object) => {
  const [items, setItems] = useState([]);
  const [item, setItem] = useState({});
  const [isPending, setIsPending] = useState();
  const [error, setError] = useState("");
  const { user } = useAuth();
  const { i18n } = useTranslation();

  let headers = {
    "Content-type": "application/json",
    "Accept-Language": i18n.language,
  };

  if (user && user.token) headers["Authorization"] = "Bearer " + user?.token;

  const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: headers,
  });

  const localizeProperty = (property) => {
    property = property.replace("fullName", "ФИО");
    property = property.replace("password", "Пароль");
    property = property.replace(" null", " пустым");
    return property;
  };

  function reportError(err) {
    let error = "";
    if (err?.response?.data?.error) error = err?.response?.data?.error;

    if (error?.violations && error?.violations.length > 0) {
      error =
        localizeProperty(error.violations[0].propertyPath) +
        ": " +
        localizeProperty(error.violations[0].title);
    }

    if (error === "" && err?.response?.status)
      error = "Server Error: " + err?.response?.status;
    if (error === "") error = "Server Error";
    if (!window.navigator.onLine && err.code === "ERR_NETWORK")
      error = "No Internet Connection";

    alert(error);

    return { error: error, resp: null };
  }

  async function get(path, isSingleItem) {
    setIsPending(true);

    const uri = path ? path : object;

    try {
      const resp = await apiClient.get(uri);
      isSingleItem ? setItem(resp.data) : setItems(resp.data);
      setError("");

      return { error: "", resp: resp.data };
    } catch (err) {
      setError(err);
      return reportError(err);
    } finally {
      setIsPending(false);
    }
  }

  async function post(data, path, config = {}) {
    setIsPending(true);

    const uri = path ? path : object;

    try {
      const resp = await apiClient.post(uri, data, config);
      setError("");
      return { error: "", resp: resp.data };
    } catch (err) {
      setError(err);
      return reportError(err);
    } finally {
      setIsPending(false);
    }
  }


  async function upload(path, formData, onUploadProgress) {
    setIsPending(true);

    const uri = path ? path : object;

    try {
      const resp = await apiClient.post(uri, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress,
      });
      setError("");
      return { error: "", resp: resp.data };
    } catch (err) {
      setError(err);
      return reportError(err);
    } finally {
      setIsPending(false);
    }
  }

  async function del(id) {
    setIsPending(true);

    const path = id > 0 ? object + "/" + id : object;
    try {
      await apiClient.delete(path);
      setError("");
    } catch (err) {
      setError(err);
      return reportError(err);
    } finally {
      setIsPending(false);
    }
  }

  return {
    items,
    item,
    isPending,
    error,
    get,
    post,
    del,
    upload,
  };
};
