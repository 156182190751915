import { format, fromUnixTime } from "date-fns";

export const TimestampCellRenderer = (p) => {
  if (p.value === undefined || p.value < 10) return "";

  return format(fromUnixTime(p.value), "dd/MM/yy");
};

export const TimeCellRenderer = (p) => {
  if (p.value === undefined || p.value < 10) return "";

  return format(fromUnixTime(p.value), "HH:mm");
};

export const StatusCellRenderer = (p) => {
  if (p.value === 0)
    return <span className="badge bg-secondary">Не активен</span>;
  if (p.value === 1)
    return <span className="badge bg-secondary">Готов к работе</span>;
  if (p.value === 2)
    return <span className="badge bg-secondary">Требует уточнений</span>;
  if (p.value === 3)
    return (
      <span className="badge bg-secondary">Временно не готов к работе</span>
    );

  return "";
};

export const RoleCellRenderer = (p) => {
  if (!p.value || p.value.length === 0) return <span>-</span>;

  let roles = "";
  p.value.map((v, _) => {
    if (roles.length > 0) roles += ", ";
    roles += v.replace("ROLE_", "");
  });

  return <span>{roles}</span>;
};

export const TitleFilterValueGetter = (p) => {
  if (p.data) {
    return p.data?.position?.title;
  }

  return "";
};

export const PositionCellRenderer = (p) => {
  if (p.value === undefined || p.value < 10) return "";

  return p.value.title;
};

export const CreatorCellRenderer = (p) => {
  if (p.value === undefined || p.value < 10) return "";

  return p.value.fullName;
};

export const WorkersCellRenderer = (p) => {
  if (p.value === undefined || p.value.length === 0) return "";

  return p.value[0].fullName;
};

export const SexCellRenderer = (p) => {
  if (p.value === undefined) return "";

  if (p.value === 0) return <span>Женский</span>;
  if (p.value === 1) return <span>Мужской</span>;

  return "";
};

// DefaultColDef sets props common to all Columns
export const DefaultColDef = {
  sortable: true,
  resizable: true,
  filter: true,
  flex: 1,
};

export const BULK_ACTION = {
  STATUS: 0,
  DELETE: 1,
};

export const onButtonBulkAction = async (
  objectAPI,
  uri,
  action,
  value,
  IDs,
  onBulkActionComplete
) => {
  if (!window.confirm("Удалить?")) return;

  await objectAPI.del("");

  onBulkActionComplete();
};
